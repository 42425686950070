import React from "react"
import { Col, Row } from "react-bootstrap"
import ContactCard from "./ContactCard"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

const ContactSection = () => {
  return (<section id="contact">
    <h2 className="mb-3">Kontakt</h2>
    <p className="mb-5" style={{maxWidth: 800}}>
      Wenn Sie sich für eines der Produkte interessieren
      oder Sie sich einfach beraten lassen möchten, dann können Sie mich
      per Whatsapp/Telefon <strong className="d-inline-block">+49 175 4150950</strong> {" "}
      oder per Email unter <strong className="d-inline-block">kim.schamberger@outlook.de</strong> erreichen:
    </p>
    <Row>
      <Col sm={{span: 8, offset: 2}}>
        <Row>
          <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
            <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="https://wa.me/491754150950" icon={faWhatsapp} iconSize="3x">
              <strong>Whatsapp</strong>
            </ContactCard>
          </Col>
          <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
            <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="tel:+49175-415-0950" icon={faPhone} iconSize="2x">
              <strong>Anruf</strong>
            </ContactCard>
          </Col>
          <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
            <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="mailto:kim.schamberger@outlook.de" icon={faEnvelope} iconSize="2x">
              <strong>Neue Email</strong>
            </ContactCard>
          </Col>
        </Row>
      </Col>
    </Row>
  </section>)
}

export default ContactSection