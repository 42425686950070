import React from "react"

import Button from "../components/basic/Button"
import PageWrapper from "../components/PageWrapper"
import { productData, ReicoCategory } from "../data/ReicoProducts"
import Product, { categoryMap } from "../components/content/Product"
import { Col, Row, Card } from "react-bootstrap"
import Checklist from "../components/basic/Checklist"
import KimiAndLucy from "../components/images/KimiAndLucy"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import ExternalLink from "../components/basic/ExternalLink"
import ContactCard from "../components/content/ContactCard"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import LucyTitle from "../components/images/LucyTitle"
import ContactSection from "../components/content/ContactSection"
import TargetCard from "../components/content/TargetCard"



const IndexPage = () => {
  return (
    <PageWrapper title="Lernen Sie der Natur zu Vertrauen" >
      <section className="mt-4">
        <Row>
          <Col style={{marginBottom: 30}}>
            <LucyTitle>
              <div
                className="d-flex p-4"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                  position: "absolute",
                  left: 30,
                  bottom: -30,
                  maxWidth: "100%",
                  borderRadius: 20}}>
                <div>
                  <h2 className="h3 mb-2">Warum Futter von Reico?</h2>
                  <p>
                    <strong>Das Beste was die Natur zu bieten hat!</strong>
                  </p>
                  <p>
                    Wir bieten
                  </p>
                  <Checklist items={[
                    "Höchste Lebensmittelqualität",
                    "Artgerechtes Futter",
                    "Persönliche Beratung",
                    "Mineralisches Gleichgewicht"
                  ]}/>
                </div>
              </div>
            </LucyTitle>
          </Col>
        </Row>
      </section>
      <hr className="my-5"/>
      <section id="produkte">
        <h2>Produkte</h2>
        <h5 className="mb-4" style={{color: "rgba(0,0,0,0.7)"}}>
          Eine Übersicht meiner Lieblingsprodukte
        </h5>
        <p style={{maxWidth: 800}}>
          Als Reico Vetriebspartner helfe ich Ihnen gerne das perfekte Futter oder Nahrungsergänzung für
          Ihren Vierbeiner oder sich selbst zu finden. Wenn Sie sich für eines der Produkte interessieren,
          können Sie die Produkte direkt bei <ExternalLink href="#contact" text="mir" /> bestellen, sich kostenlos beraten lassen und als Kunde erhalten
          Sie dann auch ihre eigenen Zugangsdaten um jederzeit selbst Bestellungen zu tätigen.
        </p>
        <p>
          Ich freue mich auf Sie und viel Spaß beim Stöbern!
        </p>

        <Row className="my-5">
          <Col sm={{span: 10, offset: 1}}>
            <Row>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <TargetCard backgroundColor="rgba(0,0,0,0.8)" href="/Kategorie/FuerHunde" imageFilename="Dog.jpg" alt={"Kategorie Hund"}>
                  <strong>Für Hunde</strong>
                </TargetCard>
              </Col>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <TargetCard backgroundColor="rgba(0,0,0,0.8)" href="/Kategorie/FuerKatzen" imageFilename="Cat.jpg" alt={"Kategorie Katze"}>
                  <strong>Für Katzen</strong>
                </TargetCard>
              </Col>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <TargetCard backgroundColor="rgba(0,0,0,0.8)" href="/Kategorie/FuerDenMensch" imageFilename="Human.jpg" alt={"Kategorie Mensch"}>
                  <strong>Für Menschen</strong>
                </TargetCard>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="d-flex justify-content-center">
          <Button href="http://shop.lucygonewild.com" text="Mehr Produkte"/>
        </div>
      </section>
      <hr className="my-5"/>
      <ContactSection />
      <hr className="my-5"/>
      <h2>Mein Blog</h2>
      <h5 className="mb-5" style={{color: "rgba(0,0,0,0.7)"}}>
        Erfahren Sie mehr auf meinem unabhängigen Block
      </h5>
      <section>
        <Row className="align-items-center">
          <Col md={6} className="mb-5">
            <Card>
              <KimiAndLucy/>
            </Card>
          </Col>
          <Col md={6} className="mb-5 px-4">
            <h4>Lucy Gone Wild!</h4>
            <p>
              Sie möchten mehr über allgemeine Themen rund um den Hund erfahren?
            </p>
            <p>
              Dann schauen Sie doch gerne bei meinem <strong>Blog</strong> vorbei:
            </p>
            <Button href="https://lucygonewild.com" text="Zu meinem Blog" />
          </Col>
        </Row>
      </section>
    </PageWrapper>
  )
}

export default IndexPage
