import React from "react"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import ProductTargetImage from "../images/ProductTargetImage"
import { Link } from "gatsby"

type Props = {
  backgroundColor: string
  children: any,
  href: string,
  imageFilename: string,
  alt: string
}

const TargetCard = (props: Props) => (
  <Link to={props.href} style={{textDecoration: "none"}}>
      <div className="d-flex justify-content-center mb-3">
        <div className="d-flex justify-content-center align-items-center p-0"
             style={{
               backgroundColor: "rgba(33, 33, 33, 0.9)",
               color: "rgba(255,255,255,0.9)",
               boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
               borderRadius: 50,
               overflow: "hidden",
               width: 200,
               height: 200}}>
          <ProductTargetImage fileName={props.imageFilename} alt={props.alt} />
        </div>
      </div>
      <div className="text-center">
        {props.children}
      </div>
  </Link>
)

export default TargetCard