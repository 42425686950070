import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Jumbotron } from "react-bootstrap"

const LucyTitle = (props: {children: any}) => {
  const alt = "Reico Lucy Title"

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "title.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div style={{padding: 0, margin: 0, overflow: "visible", position: "relative"}}>
      <div style={{
        borderRadius: 20,
        overflow: "hidden",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
      }}>
        <BackgroundImage
          Tag="div"
          style={{color: "rgba(0,0,0,0.9)", height: 400}}
          fluid={data.file.childImageSharp.fluid}
          alt={alt}>
        </BackgroundImage>
      </div>

      {props.children}
    </div>

  )
}

export default LucyTitle