import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

type Props = {
  fileName: string,
  alt: string
}

const ProductTargetImage = ({fileName, alt}: Props) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/Targets/"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const gatsbyImage = allFile.edges
    .map((edge: any) => edge.node.childImageSharp.fluid)
    .find((x: any) => x.originalName === fileName)

  return (
    <Img
      fluid={gatsbyImage}
      alt={alt}
      style={{ height: 200, width: 200}}
      imgStyle={{ objectFit: "contain" }}
       />
  )
}

export default ProductTargetImage;
