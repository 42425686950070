import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"


const Checklist = (props: {items: string[]}) => {

  const mkItem = (item: string, i: number) => (
    <li key={i} className="mb-2 ml-0">
      <FontAwesomeIcon icon={faCheck} className="mr-2" />
      {item}
    </li>
  )

  return (
    <ul style={{ listStyleType: "none", marginLeft: "1rem", paddingLeft: 0}}>
      {
        props.items.map(mkItem)
      }
    </ul>
  )

}

export default Checklist